import { createWebHistory, createRouter } from "vue-router";
import axios from 'axios';
import routes from './routes';
import appConfig from "../../app.config";
import store from '@/state/store';

const router = createRouter({
    history: createWebHistory(),
    routes,
    mode: 'history',
    scrollBehavior(to, from, savedPosition) {
        if (savedPosition) {
            return savedPosition;
        } else {
            return { top: 0, left: 0 };
        }
    },
});

router.beforeEach(async (routeTo, routeFrom, next) => {

    const authRequired = routeTo.matched.some((route) => route.meta.authRequired);
    
    if (!authRequired) return next();
    
    axios.defaults.headers.common['authorization'] = 'Bearer ' + localStorage.getItem('jwt'); 
    await axios.get('https://api-node.themesbrand.website/profile').then((data) => {
        localStorage.setItem('userdata', JSON.stringify(data.data.user));
        localStorage.setItem('userid', data.data.user._id);
        localStorage.setItem('user', JSON.stringify(data.data.user));
        next();
    }).catch(() => {
        next({ name: 'login', query: { redirectFrom: routeTo.fullPath } });
    });
});

router.beforeEach((routeTo, routeFrom, next) => {
    if (process.env.VUE_APP_DEFAULT_AUTH === "firebase") {
        
        const authRequired = routeTo.matched.some((route) => route.meta.authRequired);

        if (!authRequired) return next();

        if (store.getters['auth/loggedIn']) {
        
            return store.dispatch('auth/validate').then((validUser) => {
                validUser ? next() : next({ name: 'login', query: { redirectFrom: routeTo.fullPath } });
            });
        }
        
        next({ name: 'login', query: { redirectFrom: routeTo.fullPath } });
    } else {
        const publicPages = ['/login', '/register', '/forgot-password'];
        const authpage = !publicPages.includes(routeTo.path);
        const loggeduser = localStorage.getItem('user');

        if (authpage && !loggeduser) {
            return next('/login');
        }

        next();
    }
});

router.beforeResolve(async (routeTo, routeFrom, next) => {
    try {
        for (const route of routeTo.matched) {
            await new Promise((resolve, reject) => {
                if (route.meta && route.meta.beforeResolve) {
                    route.meta.beforeResolve(routeTo, routeFrom, (...args) => {
                        if (args.length) {
                            next(...args);
                            reject(new Error('Redirected'));
                        } else {
                            resolve();
                        }
                    });
                } else {
                    resolve();
                }
            });
        }
    } catch (error) {
        return;
    }
    document.title = routeTo.meta.title + ' | ' + appConfig.title;
    
    next();
});

export default router;
