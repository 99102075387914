export default [
    {
        path: "/pcs/purchList",
        name: "purchList",
        meta: {
          pageTitle: "구매관리",
          title: "구매주문관리",
          authRequired: true,
        },
        component: () => import("../views/pcs/purch/purchList"),
    },
    {
        path: "/pcs/purchCode",
        name: "purchCode",
        meta: {
          title: "Purchase Master Data",
          authRequired: true,
        },
        component: () => import("../views/pcs/purch/purchCode"),
    },
    {
        path: "/pcs/deliveryHis",
        name: "deliveryHis",
        meta: {
          pageTitle: "납품관리",
          title: "납품이력",
          authRequired: true,
        },
        component: () => import("../views/pcs/delivery/deliveryHis"),
    },
    {
        path: "/pcs/qrcodeHis",
        name: "qrcodeHis",
        meta: {
          pageTitle: "납품관리",
          title: "QR코드 발행이력",
          authRequired: true,
        },
        component: () => import("../views/pcs/delivery/qrcodeHis"),
    },
    {
        path: "/pcs/qrcodeScan",
        name: "qrcodeScan",
        meta: {
          pageTitle: "납품관리",
          title: "QR코드 인식",
          authRequired: true,
        },
        component: () => import("../views/pcs/delivery/qrcodeScan"),
    },
    {
        path: "/pcs/insStd",
        name: "InspectionStd",
        meta: {
          pageTitle: "검사관리",
          title: "검사기준서 관리",
          authRequired: true,
        },
        component: () => import("../views/pcs/inspection/insStd"),
    },
    {
        path: "/pcs/insResult",
        name: "InspectionResult",
        meta: {
          pageTitle: "검사관리",
          title: "검사성적서 관리",
          authRequired: true,
        },
        component: () => import("../views/pcs/inspection/insResult"),
    },
];
