export default [
    {
        path: "/sys/vendor",
        name: "VendorMaster",
        meta: {
          pageTitle: "시스템 관리",
          title: "협력사 관리",
          authRequired: true,
        },
        component: () => import("../views/system/vendorMas"),
    },
    {
      path: "/sys/vendorDetail",
      name: "vendorDetail",
      meta: {
        pageTitle: "시스템 관리",
        title: "협력사 관리",
        authRequired: true,
      },
      props: true,
      component: () => import("../views/system/vendorDetail"),
  },
    {
        path: "/sys/item",
        name: "ItemMaster",
        meta: {
          pageTitle: "시스템 관리",
          title: "품목 관리",
          authRequired: true,
        },
        component: () => import("../views/system/itemMas"),
    },
    {
        path: "/sys/user",
        name: "UserMaster",
        meta: {
          pageTitle: "시스템 관리",
          title: "사용자 관리",
          authRequired: true,
        },
        component: () => import("../views/system/userMas"),
    },
    {
        path: "/sys/menu",
        name: "MenuMaster",
        meta: {
          pageTitle: "시스템 관리",
          title: "메뉴 관리",
          authRequired: true,
        },
        component: () => import("../views/system/menuMas"),
    },
    {
        path: "/sys/code",
        name: "CodeMaster",
        meta: {
          pageTitle: "시스템 관리",
          title: "공통코드 관리",
          authRequired: true,
        },
        component: () => import("../views/system/codeMas"),
    },
];
